import React, { useEffect } from 'react'

function useIsIPad() {
  return navigator.userAgent.match(/iPad/i) !== null
}

function App() {
  const isIPad = useIsIPad()

  const urlParams = new URLSearchParams(window.location.search)
  const downloaded = JSON.parse(urlParams.get('downloaded'))

  const fromNative = urlParams.get('native') !== null

  useEffect(() => {
    if (fromNative || !isIPad) {
      return
    }

    window.location.href = 'epgrnd://'
  }, [fromNative, isIPad])

  return (
    <>
      <h3>Downloaded</h3>

      <div>{downloaded && downloaded.join(', ')}</div>
    </>
  )
}

export default App
